<template>
  <div class="accent">
    <v-row justify="center">
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-1a.png')"
          class="mt-5 ml-5"
          contain
        >
        </v-img>
      </v-col>
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-2.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo_3a.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-4a.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-5a.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>

      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-7.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-8.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>
      <v-col class="col-4 col-md-2 d-flex mr-2">
        <v-img
          :src="require('@/assets/logo_cl/logo-9.png')"
          class="mt-5"
          contain
        >
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "homeDseg",
  data() {
    return {};
  },
};
</script>

<style scoped>
.hoogte {
  height: 300px;
}
.opa {
  background-image: url("/public/smartphone.png");
  background-size: cover;
}
</style>
