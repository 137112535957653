<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="18"
        class="ma-2"
        large
        color="accent"
        v-bind="attrs"
        v-on="on"
      >
        TEST NU
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="primary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row>
        <v-col class="col-12 mt-5 col-md-6">
          <v-card-title class="primary--text">Personal Quick Scan</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2"
            >Want to know how the Personal Quick Scan works? Fill in your
            details, you will receive an invitation by mail to fill in a
            Personal Quick Scan. Personal Quick Scan. (Please note this mail
            might end up in your spam box).
          </v-card-text>
        </v-col>
        <v-col class="col-12 mt-5 col-md-6">
          <v-card-text class="body-1 grey--text text--darken-2">
            <v-form ref="form" v-model="valid" lazy lazy-validation>
              <v-text-field
                outlined
                shaped
                placeholder="Company"
                v-model="bedrijfsnaam"
                :counter="150"
                :rules="companyRules"
                required
              />
              <v-text-field
                outlined
                shaped
                placeholder="First name"
                v-model="voornaam"
                :counter="50"
                :rules="frontRules"
                required
              />
              <v-text-field
                outlined
                shaped
                placeholder="Last name"
                v-model="achternaam"
                :counter="75"
                :rules="backRules"
                required
              />
              <v-text-field
                outlined
                shaped
                placeholder="e-mail"
                v-model="email"
                :counter="100"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                outlined
                shaped
                placeholder="phone"
                v-model="telefoon"
                :counter="15"
              ></v-text-field>
              <v-btn class="primary" @click="verstuur" :disabled="!valid"
                >Send</v-btn
              >
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "dialogPqs",
  data() {
    return {
      dialog: false,
      valid: true,
      bedrijfsnaam: "",
      voornaam: "",
      achternaam: "",
      email: "",
      telefoon: "",
      team_id: 1,
      user_id: 2,
      language: "en",
      companyRules: [
        (v) => !!v || "Would you like to fill in the company name",
        (v) => (v && v.length <= 150) || "You have a maximum of 150 characters",
      ],
      frontRules: [
        (v) => !!v || "Would you like to fill in your first name",
        (v) => (v && v.length <= 50) || "You have a maximum of 150 characters",
      ],
      backRules: [
        (v) =>
          !!v ||
          "Please enter your email addressPlease enter your email address",
        (v) =>
          (v && v.length <= 150) ||
          "You have a maximum of 150 charactersYou have a maximum of 150 characters",
      ],
      emailRules: [
        (v) => !!v || "Please enter your email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "This does not appear to be a valid email address",
      ],
    };
  },
  methods: {
    closeWin() {
      this.dialog = false;
    },

    verstuur() {
      this.$refs.form.validate();
      let data = JSON.stringify({
        voornaam: this.voornaam,
        achternaam: this.achternaam,
        email: this.email,
        telefoon: this.telefoon,
        bedrijfsnaam: this.bedrijfsnaam,
        team_id: this.team_id,
        user_id: this.user_id,
        language: this.language,
      });
      // eslint-disable-next-line no-undef
      axios.post("/websitePQScreateEn", data, {
        headers: {},
      });
      this.$swal({
        icon: "success",
        title: "Sent",
        text: "We have received your application, you will receive by email an invitation for a Personal Quick Scan.",
        showConfirmButton: false,
        timer: 1200,
      })
        .then((response) => {
          // eslint-disable-next-line
          console.log(response);
          this.$refs.form.reset();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops something goes wrong",
            text: "Watch out something is not going well",
            footer:
              "Please try again if you continue to have problems would you please contact us?",
          });
        });
    },
  },
};
</script>

<style scoped></style>
