<template>
  <div>
    <v-dialog v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text color="accent" v-bind="attrs" v-on="on">
          Read more
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary--text"> Family charter App </v-card-title>

        <v-card-text class="body-1 grey--text text--darken-2">
          Family charter App, the consulting method of the future. <br />
          <br />
          In the B.C. period (Before Corona), Trustedaccountant.nl was already
          developing a new consultancy method that guides complex group
          processes such as the family status guided with the help of digital
          tooling.<br />
          <br />This new method has already led to significant successes at
          accounting firms such as Ernst & Young and FLYNTH. Clients praise the
          ability to read the different perspectives on the family business at
          their own time and pace and to respond to the corresponding theses.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "newsB",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>
