<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="accent">
                <v-icon> mdi-chess-rook </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center primary--text"
              >Solid foundation</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  The Family Charter is based on the 3-circle model, the
                  distinction between the family, owner and business interests.
                  In addition, use has been made of the insights from years of
                  scientific research and from own knowledge and experience in
                  advising family businesses.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "kenmA",
};
</script>

<style scoped></style>
