<template>
  <div class="secondary mt-5">
    <v-row class="ml-5 mt-5 mr-5">
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_1a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Emergency Plan BV</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            With this Contingency Plan, you map out an operating company's the
            wishes of the entrepreneur are linked to the actual company
            information. It then follows that various measures can be taken to
            (temporarily) assume the role of the entrepreneur during an
            (temporarily) during an emergency situation and to implement the
            ownership strategy of the entrepreneur to fruition.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_2a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Emergency Plan PH</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            This Contingency Plan describes the position of the Personal Holding
            relation to the operating companies, the role of the entrepreneur as
            director and all agreements regarding the assets that are managed by
            the personal holding company. A new director knows how the
            management of the operating companies should must be filled in.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_3a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Emergency Plan VOF</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            For each partner it becomes clear what role the partner has within
            the company, what agreements have been made with the other partners
            in a crisis situation and provides a complete overview of the
            company. A life partner can use the Emergency Plan in a crisis
            situation to represent the interests of the partner.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-3 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/noodplan/np_4a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text"
            >Emergency plan Eenmanszaak</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            The role of the entrepreneur is crucial in the business. Hence, a
            full account of the roles and responsibilities, the key stakeholders
            and activities, and the wishes of the entrepreneur in a crisis
            situation is central. It gives the observer or successor a complete
            picture of what needs to happen if the entrepreneur is (temporarily)
            unable to lead.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "noodCseg",
};
</script>

<style scoped></style>
