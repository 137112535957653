<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text"> Asset control </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Asset control is about your client's entire assets. Business,
          semi-business and private. How do you provide insight into the What is
          the return/risk ratio for all of the assets? How do you relate the
          asset components to the important wishes and goals? How do you advise
          and assist your client with the various issues such as governance,
          investing, financing, investing, etc. during the phases of wealth
          generation, wealth preservation and wealth transfer.<br />
          <br />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_prodverm2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "vermAseg",
};
</script>

<style scoped></style>
