<template>
  <div>
    <v-dialog v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text color="accent" v-bind="attrs" v-on="on">
          Read more
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary--text"> Vulnerable</v-card-title>

        <v-card-text class="body-1 grey--text text--darken-2">
          Especially in Corona time we experience how vulnerable we are. <br />
          <br />
          How many entrepreneurs have had to contend with COVID-19 is not known.
          But it is conceivable that the impact of an entrepreneur who is
          seriously ill and cannot be reached to lead his business weighs
          heavily.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "newsC",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>
