<template>
  <v-row class="info" no-gutters>
    <v-col class="col-md-1"></v-col>
    <v-col class="col-12 mt-5 mb-5 ml-15 col-md-4">
      <v-img
        :src="require('@/assets/white/white_devices.png')"
        width="70%"
        class="mt-5"
      >
      </v-img>
    </v-col>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mb-5 mr-5">
        <v-card-title class="primary--text"> White Label </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Our goal is to create a maximum customer experience. We implement your
          corporate identity in all Apps, e-mails, documents, PowerPoints and
          other expressions. We can even connect to your advisors email box of
          your advisors, so that your client can communicate directly with your
          advisor communicates directly with your advisor through our online
          platform. You provide us with your logo and corporate colors, so that
          we can incorporate them into the online platform.
          <br /><br />
          If desired, we can convert the interface for the users (your
          employees) If you wish, we can convert the interface for the users
          (your employees) to your house style. This way you have a complete
          experience of an own online platform for both your employees and your
          customers.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "whiteLabel",
};
</script>

<style scoped></style>
