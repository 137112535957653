<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text"> Family charter </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Many family businesses experience the process of creating a family
          statute as useful, but also as time-consuming and expensive. This has
          mainly to do with the the investment of time that is required of them
          in order to hold interviews with a consultant prior to the family
          consultation. <br />
          <br />We have replaced this classic consultancy process with smart
          technology. With the Family Statute App, family members can work
          independently at their own pace and time. With this modern approach,
          you achieve a high level of involvement with the individual family
          members, that people speak the same language among themselves and that
          the decision-making is recorded in a structured manner.<br />
          <br />From the data in the Family Status App, you prepare the family
          consultation. Because you already have insight into the individual
          opinions, you can effectively address the issues on which the family
          members do not (yet) agree on. Our experience is that the family
          agrees on 80 to 90% of all issues. Precisely the part where there are
          different opinions is where you want to focus your attention.<br />
          <br />
          Because our online platform generates all the necessary documentation
          for the process, advisors experience it as a uniform method of
          working, which speed and efficiency. Family members experience an
          approach that offers more perspectives, is user-friendly, flexible and
          objective.
          <br /><br />

          <dialog-fam-video />
          View the animation
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/famstat/fam_sega2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import dialogFamVideo from "@/components/home/dialogFamVideo";
export default {
  name: "famAseg",
  components: { dialogFamVideo },
};
</script>

<style scoped></style>
