<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5 mb-5">
        <v-card-title class="primary--text">
          EMERGENCY SUCCESSION: REDUCE THE IMPACT
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          In many SMBs, business continuity is strongly linked to the
          entrepreneur himself. Suppose that the entrepreneur, due to an
          accident or illness is unable to do business for a longer period of
          time. Who will then take care of the company? The partner of the
          entrepreneur? The children? Are they able to continue the business on
          their own? What must they then know and be able to do? <br />
          <br />These and other questions surround emergency succession. You, as
          an advisor a unique position to reduce the impact of emergency
          succession reduce the impact of emergency succession. With our
          Emergency Succession Plan, you capture the future vision and ownership
          strategy of the entrepreneur. Coupled to this is your advice and
          guidance to implement the measures that need to be taken. implement.
          The Contingency Plan is a manual for the family of the entrepreneur,
          who thus has clarity.<br />
          <br />

          <dialog-nood-video />
          Watch the animation
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_prodnood2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import dialogNoodVideo from "@/components/noodplan/dialogNoodVideo";
export default {
  name: "noodAseg",
  components: { dialogNoodVideo },
};
</script>

<style scoped></style>
