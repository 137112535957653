import { render, staticRenderFns } from "./dialogPcmVideo.vue?vue&type=template&id=205e8048&scoped=true&"
import script from "./dialogPcmVideo.vue?vue&type=script&lang=js&"
export * from "./dialogPcmVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205e8048",
  null
  
)

export default component.exports