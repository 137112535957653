<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="accent">
                <v-icon> mdi-folder-information </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center primary--text"
              >Capture changes</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  An SME business is constantly changing. The SME entrepreneur
                  has to deal with many changes that can affect his original
                  wishes about the future of the company. With the Emergency
                  Plan, you keep this topic under review together with the
                  entrepreneur. Changes are easy to record, so that in a crisis
                  situation there is always an up-to-date reference present.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "noodKenF",
};
</script>

<style scoped></style>
