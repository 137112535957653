<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small text color="accent" v-bind="attrs" v-on="on">
        Read more
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="primary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="primary--text">
        Personal Content Marketing
      </v-card-title>

      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl launches Personal Content Marketing <br />
        <br />Personal Content Marketing offers accounting firms new digital
        opportunities to shape the customer journey. How do you ensure ensure
        that your firm unlocks knowledge that is personal, accessible and
        directly applicable to your clients? <br />
        <br />Personal Content Marketing offers you the opportunity to use
        digital tools to explore and meet customer needs in a fraction of the
        time you currently spend on it.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "newsA",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeWin() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
