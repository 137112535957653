import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Familiestatuut from "@/views/Familiestatuut";
import Noodplan from "@/views/Noodplan";
import Vermogensregie from "@/views/Vermogensregie";
import PersContMark from "@/views/PersContMark";
import Profiel from "@/views/Profiel";
import Nieuws from "@/views/Nieuws";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pcm",
    name: "pcm",
    component: PersContMark,
  },
  {
    path: "/profiel",
    name: "profiel",
    component: Profiel,
  },
  {
    path: "/nieuws",
    name: "nieuws",
    component: Nieuws,
  },
  {
    path: "/familiestatuut",
    name: "Familiestatuut",
    component: Familiestatuut,
  },
  {
    path: "/noodplan",
    name: "Noodplan",
    component: Noodplan,
  },
  {
    path: "/vermogensregie",
    name: "Vermogensregie",
    component: Vermogensregie,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
