<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="accent">
                <v-icon> mdi-face </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center primary--text"
              >Choosing an Observer and Successor</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  One of the most important steps is to designate someone who
                  can act as an observer or successor to the entrepreneur if the
                  entrepreneur is not able to manage the business. For the
                  entrepreneur this is a difficult choice. Who is suitable? Who
                  wants to take over? You can help the entrepreneur with this
                  choice.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "noodKenB",
};
</script>

<style scoped></style>
