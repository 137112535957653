<template>
  <v-app>
    <toolbar />
    <transition
      enter-active-class=" delay animate__animated animate__slideInDown"
      appear
    >
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import toolbar from "@/components/toolbar";
export default {
  name: "App",

  components: {
    toolbar,
  },
};
</script>
<style>
.delay {
  animation-delay: 0.5s;
}
html,
body {
  overflow-x: hidden;
}
</style>
