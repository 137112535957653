<template>
  <v-col class="col-12 col-md-4 d-flex">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img
            :src="require('@/assets/home/home_prodnood2.jpg')"
            width="100%"
          >
          </v-img>
          <v-card-title class="primary--text">Emergency plan</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            For every entrepreneur, financial independence comes into play.
            Specifically "What can I afford myself with the assets I have?". We
            use a three-step method to answer this question. Read along here how
            you can use this in your SME advisory practice.
          </v-card-text>
          <v-card-actions class="hidden-md-and-up">
            <v-btn class="accent" @click="$router.push('noodplan')"
              >Read more</v-btn
            >
          </v-card-actions>

          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="warning">
              <v-btn class="accent" @click="$router.push('noodplan')"
                >Read more</v-btn
              >
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  name: "prodNood",
};
</script>

<style scoped></style>
