<template>
  <div>
    <h2 class="info--text mb-3 mt-5">Family charter App</h2>
    <div class="body-1 grey--text text--darken-2">
      Family charter App, the consulting method of the future....
    </div>
  </div>
</template>

<script>
export default {
  name: "headerB",
};
</script>

<style scoped></style>
