<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          The Future of Advice
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Your customer expects you to be able to share information 24/7 in a
          personalized and effective way of sharing information. Via the device
          and channel that your customer chooses. Where the customer wants to be
          able to understand and respond in their own The customer wants to be
          able to understand and respond to this in his or her own time and
          pace. The customer as director of the 'conversation'. <br />
          <br />In the near future, "robo-advisors" and chat robots may take
          some of knowledge sharing from you. It's not that far yet, but It is
          clear that you are being challenged to find new methods of meet the
          communication needs of your customer. Our technology helps you to
          share your knowledge with your customers, the urgency and priority of
          your client's issues and to and thereby accelerate and optimize your
          consulting processes. optimize your consulting processes.
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_robot2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "homeAseg",
};
</script>

<style scoped></style>
