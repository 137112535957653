<template>
  <v-row no-gutters>
    <v-col class="col-121 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_lamp2a.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
    <v-col class="col-12 col-md-6 mt-5">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text"
          >Your content, our technology</v-card-title
        >
        <v-card-text class="body-1 grey--text text--darken-2">
          Has our technology and approach inspired you? Do you have a theme
          theme, issue or product that you want to bring to the attention of
          your customers quickly and effectively? attention of your customers
          quickly and effectively?
          <br /><br />
          How it works. Together we take stock of how your content can be
          translated with our technology can be translated into personalized,
          practical and applicable knowledge for your customers. Which target
          groups do you want to reach and via which channel. Which method fits
          best and which tooling is is needed for the advisor to conduct the
          advisory talks (PowerPoints, Word documents, etc.).
          <br /><br />
          Based on this inventory, we take the step of making the content
          concrete through animations, texts, questions & answers, etc. Once
          this content is complete, we program it into our test environment.
          Depending on the complexity and our planning this can be completed in
          a few days. You are then given the opportunity to test it. If the test
          is approved, then we implement immediately in your online platform.
        </v-card-text>
        <v-card-title class="primary--text">Topicality</v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Because of our pragmatic approach, we can apply short development
          times apply. This makes our approach highly suitable for bringing
          current themes or issues quickly to the attention of your customers.
          For example, through a social media campaign that refers to a Personal
          Landing Page that we set up and manage for you.
          <br /><br />
          Want to know more? Contact us for an initial meeting.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "homeEseg",
};
</script>

<style scoped></style>
