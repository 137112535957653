<template>
  <v-row no-gutters>
    <v-col class="hidden-sm-and-down col-md-6 mb-5">
      <v-img
        :src="require('@/assets/pcm/pcm_segb.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>

    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text"> Personal Quick Scan </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          A Personal Quick Scan is a mini app, specific to the client and loaded
          with one or more themes or issues. On the basis of By means of visuals
          and questions, the customer can orient himself and assess whether it
          applies to him. This method provides a user-friendly and fast way of
          sharing knowledge, creating awareness and interest. The data from the
          app translates itself into, for example, a report or personal white
          paper for the customer and a PowerPoint presentation that the advisor
          can use for the follow-up interview. <br />
          <br />Want to know how the Personal Quick Scan works? Use the
          button!<br />
          <br />

          <br /><br />
          <dialog-pqs></dialog-pqs>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dialogPqs from "@/components/pcm/dialogPqs";
export default {
  name: "pcmBseg",
  components: { dialogPqs },
};
</script>

<style scoped></style>
