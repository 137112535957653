<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small text color="accent" v-bind="attrs" v-on="on">
        Privacy statement
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="primary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="primary--text"> Privacy statement </v-card-title>

      <v-card-text class="body-1 grey--text text--darken-2">
        Laapheer BV, located at W. Vrijlandtstraat 8, 3262 GN Oud-Beijerland, is
        responsible for the processing of personal data that left behind on the
        website https://trustedaccountant.nl as shown in this privacy statement.
        Full statement in english up on request.
        <br /><br />
        Contact information:<br /><br />
        https://trustedaccountant.nl<br />
        W. Vrijlandtstraat 8<br />
        3262 GN Oud-Beijerland<br />
        0294-265335<br />
        Arie Heerschap is de Functionaris Gegevensbescherming van Laapheer BV
        Hij/zij is te bereiken via arie.heerschap@trustedaccountant.nl<br />
        <br />
        Persoonsgegevens die wij verwerken<br />
        Laapheer BV verwerkt je persoonsgegevens doordat je gebruik maakt van
        onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.<br /><br />

        Hieronder vind je een overzicht van de persoonsgegevens die wij
        verwerken:<br />
        - Voor- en achternaam<br />
        - Bedrijfsnaam<br />
        - Adresgegevens<br />
        - Telefoonnummer<br />
        - E-mailadres<br />
        - Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door een
        profiel op deze website aan te maken, in correspondentie en
        telefonisch<br />
        <br />
        Bijzondere en/of gevoelige persoonsgegevens die wij verwerken<br />
        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen
        over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming
        hebben van ouders of voogd. We kunnen echter niet controleren of een
        bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn
        bij de online activiteiten van hun kinderen, om zo te voorkomen dat er
        gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
        Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke
        gegevens hebben verzameld over een minderjarige, neem dan contact met
        ons op via info@trustedaccountant.nl, dan verwijderen wij deze
        informatie.<br /><br />

        Met welk doel en op basis van welke grondslag wij persoonsgegevens
        verwerken<br />
        Laapheer BV verwerkt jouw persoonsgegevens voor de volgende doelen:<br />
        - Verzenden van onze nieuwsbrief en/of reclamefolder<br />
        - Je te kunnen bellen of e-mailen indien dit nodig is om onze
        dienstverlening uit te kunnen voeren<br />
        - Je te informeren over wijzigingen van onze diensten en producten<br />
        - Laapheer BV verwerkt ook persoonsgegevens als wij hier wettelijk toe
        verplicht zijn, zoals gegevens die wij nodig hebben voor onze
        belastingaangifte. <br />
        <br />
        Geautomatiseerde besluitvorming<br />
        Laapheer BV neemt geen besluitvorming op basis van geautomatiseerde
        verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen
        hebben voor personen. Het gaat hier om besluiten die worden genomen door
        computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld
        een medewerker van Laapheer BV) tussen zit.<br /><br />

        Hoe lang we persoonsgegevens bewaren<br />
        Laapheer BV bewaart je persoonsgegevens niet langer dan strikt nodig is
        om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij
        hanteren de volgende bewaartermijnen voor de volgende (categorieën) van
        persoonsgegevens:<br />
        Ontvangen via contactformulier: na telefonisch contact of e-mail contact
        kunnen gegevens maximaal 12 maanden worden bewaard, mits sprake is van
        een product of dienst die via de website wordt afgenomen.<br />

        Delen van persoonsgegevens met derden<br />
        Laapheer BV verstrekt persoonsgegevens uitsluitend en alleen als dit
        nodig is voor de uitvoering van onze overeenkomst met jou of om te
        voldoen aan een wettelijke verplichting.<br />

        Cookies, of vergelijkbare technieken, die wij gebruiken<br />
        Laapheer BV gebruikt geen cookies of vergelijkbare technieken.<br />

        Gegevens inzien, aanpassen of verwijderen <br />
        Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te
        verwijderen. Dit kun je zelf doen via de persoonlijke instellingen van
        jouw account. Daarnaast heb je het recht om je eventuele toestemming
        voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van jouw persoonsgegevens door ons bedrijf en heb je het
        recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een
        verzoek kan indienen om de persoonsgegevens die wij van jou beschikken
        in een computerbestand naar jou of een ander, door jou genoemde
        organisatie, te sturen.<br />
        <br />
        Wil je gebruik maken van je recht op bezwaar en/of recht op
        gegevensoverdraagbaarheid of heb je andere vragen/opmerkingen over de
        gegevensverwerking, stuur dan een gespecificeerd verzoek naar
        info@trustedaccountant.nl.
        <br /><br />
        Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan,
        vragen wij jou een kopie van je identiteitsbewijs bij het verzoek mee te
        sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de
        strook met nummers onderaan het paspoort), paspoortnummer en
        Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy.
        Laapheer BV zal zo snel mogelijk, maar in ieder geval binnen vier weken,
        op jouw verzoek reageren.
        <br /><br />
        Laapheer BV wil je er tevens op wijzen dat je de mogelijkheid hebt om
        een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
        Persoonsgegevens. Dat kan via de volgende link:
        <br />https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
        <br />
        Hoe wij persoonsgegevens beveiligen<br />
        Laapheer BV neemt de bescherming van jouw gegevens serieus en neemt
        passende maatregelen om misbruik, verlies, onbevoegde toegang,
        ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als
        jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er
        aanwijzingen zijn van misbruik, neem dan contact op met onze
        klantenservice of via info@trustedaccountant.nl<br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "proPriv",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeWin() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
