import { render, staticRenderFns } from "./VideoComponentProfiel.vue?vue&type=template&id=60e39e46&scoped=true&"
import script from "./VideoComponentProfiel.vue?vue&type=script&lang=js&"
export * from "./VideoComponentProfiel.vue?vue&type=script&lang=js&"
import style0 from "./VideoComponentProfiel.vue?vue&type=style&index=0&id=60e39e46&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e39e46",
  null
  
)

export default component.exports