<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          Personal Landing Page
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          A Personal Landing Page is a web page and can be linked to the
          corporate website or social media campaign. The content consists of
          texts, visuals (animations or videos) and a number of questions that
          the visitor can answer. It is an effective and very user-friendly way
          of sharing knowledge and making it applicable to the situation of the
          visitor.<br /><br />Want to know how the Personal Landing Page works?
          Take the test of the Personal Quick Scan. The Personal Landing Page
          has a similar structure and offers almost the same possibilities.
          <br /><br />
          <dialog-pqs />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6">
      <v-img
        :src="require('@/assets/pcm/pcm_segc2a.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import DialogPqs from "@/components/pcm/dialogPqs";
export default {
  name: "pcmCseg",
  components: { DialogPqs },
  methods: {
    toLp() {
      window.open("https://trustedaccountant.nl", "_blank");
    },
  },
};
</script>

<style scoped></style>
