<template>
  <v-row no-gutters class="pt-8 mr-5 ml-5">
    <v-col class="col-12 col-md-3 order-3 order-md-1">
      <v-sheet class="transparent">
        <v-card-title class="primary--text"> <br /> </v-card-title>
        <v-card-text>
          <v-img
            :src="
              require(`@/assets/logo_${
                $vuetify.theme.dark ? 'light' : 'dark'
              }.png`)
            "
            height="200vh;"
            max-height="1200px"
          />
        </v-card-text>
        <v-card-title class="primary--text"> Trusted Accountant</v-card-title>
        <v-card-text
          >W. Vrijlandtstraat 8<br />
          3262 GN Oud-Beijerland<br />
          The Netherlands<br />
          email: info@trustedaccountant.nl<br />
          tel: Arie Heerschap: +31 6 1963 1986
        </v-card-text>
      </v-sheet>
    </v-col>
    <v-col class="col-12 col-md-4 order-2 order-md-2">
      <v-card class="transparent">
        <v-card-title class="primary--text">Latest News</v-card-title>
        <v-card-text>
          <header-a />
          <news-a />
          <header-b />
          <news-b />
          <header-c />
          <news-c />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="col12 col-s-5 col-md-5 order-1 order-md-3">
      <v-sheet class="transparent">
        <v-card-title class="primary--text"> Contact</v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy lazy-validation>
            <v-textarea
              outlined
              shaped
              placeholder="Your question"
              v-model="message"
              :counter="200"
              :rules="messageRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="Company name"
              v-model="company_name"
              :counter="150"
              :rules="companyRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="First name"
              v-model="contact_front"
              :counter="50"
              :rules="frontRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="Last name"
              v-model="contact_back"
              :counter="150"
              :rules="backRules"
              required
            />
            <v-text-field
              outlined
              shaped
              placeholder="e-mail"
              v-model="contact_email"
              :counter="150"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-text-field
              outlined
              shaped
              placeholder="phone number"
              v-model="contact_tel"
              :counter="15"
            ></v-text-field>
            <v-btn
              large
              dark
              color="accent"
              :disabled="!valid"
              @click="verstuur"
              >Send</v-btn
            >
          </v-form>
        </v-card-text>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import headerA from "@/components/news/headerA";
import newsA from "@/components/news/newsA";
import headerB from "@/components/news/headerB";
import newsB from "@/components/news/newsB";
import headerC from "@/components/news/headerC";
import newsC from "@/components/news/newsC";
import axios from "axios";
export default {
  name: "Footer",
  components: { headerA, newsA, headerB, newsB, headerC, newsC },
  data() {
    return {
      valid: true,
      message: null,
      team_id: 1,
      user_id: 2,
      language: "en",
      company_name: null,
      contact_front: "",
      contact_back: "",
      contact_email: "",
      contact_tel: "",
      messageRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length <= 200) || "You have a maximum of 200 characters",
      ],
      companyRules: [
        (v) => !!v || "Would you like to fill in the company name",
        (v) => (v && v.length <= 150) || "You have a maximum of 150 characters",
      ],
      frontRules: [
        (v) => !!v || "Would you like to fill in your first name",
        (v) => (v && v.length <= 50) || "You have a maximum of 150 characters",
      ],
      backRules: [
        (v) => !!v || "Would you like to fill in your last name",
        (v) => (v && v.length <= 150) || "You have a maximum of 150 characters",
      ],
      emailRules: [
        (v) => !!v || "Please enter your email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "This does not appear to be a valid email address",
      ],
      telRules: [
        (v) => (v && v.length <= 15) || "You have up to 15 characters",
      ],
    };
  },
  methods: {
    verstuur() {
      this.$refs.form.validate();
      let data = JSON.stringify({
        message_client: this.message,
        first_name_client: this.contact_front,
        last_name_client: this.contact_back,
        email_client: this.contact_email,
        phone_number_client: this.contact_tel,
        company_name_client: this.company_name,
        language_client: "en",
      });
      // eslint-disable-next-line no-undef
      axios.post("/websiteContact", data, {
        headers: {},
      });
      this.$swal({
        icon: "success",
        title: "Sent",
        text: "We have received your question, you will receive a confirmation by email immediately.",
        showConfirmButton: false,
        timer: 1200,
      })
        .then((response) => {
          // eslint-disable-next-line
          console.log(response);
          this.$refs.form.reset();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops something goes wrong.",
            text: "Watch out something is not going well.",
            footer:
              "Please try again if you continue to have problems would you please contact us?",
          });
        });
    },
  },
};
</script>

<style scoped></style>
