<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6 mb-5">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text"> Company Profile </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          As a service provider, Trustedaccountant.nl focuses on the large and
          medium-sized accountancy firms that want to invest in increasing the
          effectiveness of their increasing the effectiveness of their advisory
          practice. To this end To this end, we supply online solutions with
          modern techniques to strengthen customer experience and loyalty.
          <br /><br />
          We also provide (digital) products to address specific issues and
          governance of assets, involving various stakeholders in the
          decision-making process. stakeholders in the decision-making process.
          be involved in the decision-making process. Our solutions are
          delivered in the house style of the accounting firm. We apply the
          highest standards when it comes to data protection and security.
          <br /><br />
          Our ambition is to be an international service provider for the
          financial services that works together in a personalized collaborates
          and builds products that further shape the digital transition the
          industry is facing.
          <br /><br />
          Trustedaccountant.nl is a trade name of Laapheer BV.<br />
          Laapheer BV is located at W. Vrijlandtstraat 8, 3262 GN
          Oud-Beijerland<br />
          Kamer van Koophandel nr 56449593<br />BTW nummer NL852130648B01
          <br />Bankrekening Rabobank NL36RABO0174923678<br />
          <br />

          <pro-priv />
          <profile-alg />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6 mb-5">
      <v-img
        :src="require('@/assets/pcm/pcm_sega2a.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import proPriv from "@/components/profile/proPriv";
import ProfileAlg from "@/components/profile/profileAlg";

export default {
  name: "profileAseg",
  components: {
    ProfileAlg,
    proPriv,
  },
};
</script>
<style scoped></style>
