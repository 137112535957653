<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="accent">
                <v-icon> mdi-bookshelf </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center primary--text"
              >Share knowledge</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  The Family Charter App offers all family members involved in
                  an identical way to knowledge. Knowledge in clear language,
                  short and concise explanations and, where possible, with
                  visual support. The pace at which knowledge is gained is
                  determined by the individual family member. This way we tailor
                  the experience to all family members with the goal of making
                  informed decisions as individuals and make as individuals work
                  from the same picture and with the same language as a group.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "kenmE",
};
</script>

<style scoped></style>
