<template>
  <v-row no-gutters>
    <v-col class="hidden-sm-and-down col-md-6 mb-5">
      <v-img
        :src="require('@/assets/home/home_pcm2.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>

    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          Personal Content Marketing
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Personal Content Marketing enables you to take quick steps in setting
          up customer journeys on the one hand and digital tooling for your for
          your advisors on the other. <br /><br />With our technology, you let
          the customer decide which issues are urgent and important. The
          starting point for this can be your corporate website, your social
          media campaigns or the advisor. So you know what gets the attention of
          your customer. You can then respond to it more effectively.
          <br /><br />

          <dialog-pcm-video></dialog-pcm-video>
          Watch the animation
          <v-btn plain color="accent" @click="$router.push('pcm')"
            >or read on</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import dialogPcmVideo from "@/components/pcm/dialogPcmVideo";
export default {
  name: "homeBseg",
  components: { dialogPcmVideo },
};
</script>

<style scoped>
.btnAn {
  animation-duration: 20s;
}
</style>
