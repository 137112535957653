<template>
  <v-row class="secondary" no-gutters>
    <v-col class="col-12 mt-5 col-md-6">
      <v-card elevation="15" shaped class="ml-5 mr-5">
        <v-card-title class="primary--text">
          Personal Content Marketing
        </v-card-title>
        <v-card-text class="body-1 grey--text text--darken-2">
          Personal Content Marketing is a deepening of Content Marketing and It
          enables you to translate themes or issues into personal and personal
          and directly applicable information for your customer. Where corporate
          storytelling and related content marketing are written are written for
          a broad audience, Personal Content Marketing is about applying the
          information to the customer, so that they can make up their mind for
          themselves whether can make the decision whether the issue is urgent
          and important enough to pay attention to.<br /><br />
          <dialog-pcm-video></dialog-pcm-video>
          Watch the animation
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-12 col-md-6">
      <v-img
        :src="require('@/assets/pcm/pcm_sega2a.jpg')"
        width="100%"
        class="mt-5"
      >
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import dialogPcmVideo from "@/components/pcm/dialogPcmVideo";
export default {
  name: "pcmAseg",
  components: { dialogPcmVideo },
};
</script>

<style scoped></style>
