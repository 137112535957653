<template>
  <div class="secondary mt-5">
    <v-row class="ml-5 mt-5 mr-5">
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/famstat/fs_full2.jpg')" height="250px">
          </v-img>
          <v-card-title class="primary--text"
            >Family charter full version</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            In the full version, we offer 11 different perspectives on the
            family business. This version can be used for both large and
            medium-sized family businesses.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/famstat/fs_lt.jpg')" height="250px">
          </v-img>
          <v-card-title class="primary--text"
            >Family charter Light</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            This version has 5 modules that include the main components for a
            family succession. This version is intended for smaller family
            businesses with a less complex situation. For example, a transfer
            from father to son.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/famstat/fs_spec2.jpg')" height="250px">
          </v-img>
          <v-card-title class="primary--text">Special</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            For groups with complex decision-making processes, we build similar
            solutions. Think of the Partner Statute for corporate or real estate
            owners, the Wealth Statute for prosperous families and the
            Investment Statute for Associations & Foundations.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "famCseg",
};
</script>

<style scoped></style>
