<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="15"
        class="ma-2"
        large
        fab
        color="accent"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-video</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="primary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <vimeo-player
        :video-id="490769243"
        ref="player"
        class="embed-container"
        :options="{ responsive: true }"
      ></vimeo-player>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialogNoodVideo",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeWin() {
      this.$refs.player.pause();
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
