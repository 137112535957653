import { render, staticRenderFns } from "./newsA.vue?vue&type=template&id=a3366ece&scoped=true&"
import script from "./newsA.vue?vue&type=script&lang=js&"
export * from "./newsA.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3366ece",
  null
  
)

export default component.exports