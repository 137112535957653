<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small text color="accent" v-bind="attrs" v-on="on">
        General Terms and Conditions
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon color="primary" @click="closeWin">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon color="primary" class="btnAn" @click="closeWin">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title class="primary--text">
        General Terms and Conditions <br /><br />
        INTRODUCTION
      </v-card-title>

      <v-card-text class="body-1 grey--text text--darken-2">
        The service offered by Trustedaccountant.nl is for all customers. Every
        day, Trustedaccountant.nl is working to improve its service. It is
        possible that Trustedaccountant.nl may well make adjustments to its
        service in order to make the improve the cooperation with the customers
        even more. The various topics that apply to the collaboration are
        described below in are described below in separate chapters. Together,
        these chapters these chapters form the complete Service Level
        Agreement,General General Terms and Conditions and Processing Agreement.
      </v-card-text>
      <v-card-title class="primary--text"> SLA/AV </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        These terms and conditions apply to all agreements that Laapheer BV
        (Trustedaccountant.nl) concludes. Trustedaccountant.nl strives for
        transparent and simple conditions, so that not only a lawyer but
        everyone can understand them. They are provided when the agreement is
        agreement or made available as a clickable hyperlink in PDF format. made
        available as a PDF. If conditions are unclear, then Trustedaccountant.nl
        is happy to hear about it. Individual agreements in the agreed agreed
        upon in the product agreement are also applicable and are leading if
        they conflict with the SLA/AV.
      </v-card-text>
      <v-card-title class="primary--text">
        Applicable Law and Disputes.
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The SLA/AV are governed by Dutch law. The Vienna Sales Sales Convention,
        which has its own rules for international purchase agreements, is
        excluded. Should any disputes arise between arise between client and
        Trustedaccountant.nl, they will be exclusively brought submitted to a
        competent court in the Netherlands. If one or more provisions are void
        or voidable, the remaining provisions will continue to be remain in
        force.
      </v-card-text>
      <v-card-title class="primary--text"> Wijzigingen </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl works every day to develop its software that make
        it easier to work with. The SLA/AV can The SLA/AV can also change in
        line with this, whereby the starting point is that these changes will
        benefit the quality of Trustedaccountant.nl services. services.
        <br />Changes will always be made known by e-mail, whereby major changes
        will also be mentioned in a special newsletter. will also be mentioned
        in a special newsletter. In the event that the Trustedaccountant.nl
        software and Trustedaccountant.nl services continue to be used, the most
        recent General General Terms and Conditions and SLA' apply. It is
        possible to objections to the changes can be made by submitting an
        improvement suggestion'. We will then assess whether the We will then
        assess whether the change can lead to adjustment. In extreme cases there
        is the possibility of terminating the agreement after the change, in
        which case the old conditions will remain valid for 2 months. Agreement
        The agreement is entered into for an indefinite period of time with a
        minimum of One year, unless otherwise agreed. By entering into an By
        entering into an agreement, the client receives a license to use the
        Trustedaccountant.nl software. This license is billed via a
        subscription. This is invoiced in the form of a subscription. License
        The license includes the purchased product components (e.g. users,
        employees and environments) with the corresponding numbers and
        corresponding numbers and start and end dates. The license may may only
        be used for the customer's own company(ies). The scope of the license
        will, unless otherwise agreed, be geared to the number of employees of
        the organization. Employees can then have user, administrator, or other
        roles.
      </v-card-text>
      <v-card-title class="primary--text"> Pricing </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        To determine the price of the license, Trustedaccountant.nl uses, among
        other things tiers based on purchase quantities and prices per
        sector/industry. sector/industry. When increasing or decreasing the
        license numbers, the price the price scale can change. A specification
        of the offered A specification of the products, prices and increments
        offered can be found in the section Licenses & Prices" section in the
        contract. Trustedaccountant.nl conducts a market Trustedaccountant.nl
        maintains a policy regarding prices that is in accordance with the
        market and price index. Trustedaccountant.nl can index the prices
        annually, taking into account the annual mutation of the price
        Trustedaccountant.nl can index its prices annually, taking into account
        the annual change in the Consumer Price Index of last October. In case
        of indexation, Trustedaccountant.nl will send a message to clients
        before 1 December 1, Trustedaccountant.nl sends a message to its clients
        in which the percentage is mentioned. The indexation will take effect
        from the first invoice of the following calendar year. calendar year.
      </v-card-text>
      <v-card-title class="primary--text"> Facturatie </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl invoices:<br />
        ·Licenses and management/maintenance fees: annually in advance;<br />
        ·Consultancy: weekly, in arrears or by agreed deadlines;<br />
        ·Training: at the start of the training -Other costs such as
        implementation or house style costs: one-off costs prior to the start of
        implementation.<br />
        The subscription invoice will show the current numbers for each product.
        All invoices are sent by email in PDF file to.<br />
        The payment period is 30 days, unless otherwise agreed. As soon as all
        financial obligations have been fulfilled, the license is automatically
        renewed for one year.
      </v-card-text>
      <v-card-title class="primary--text">
        Change of subscription
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Changes to the license may be requested by e-mail to
        administratie@trustedaccountant.nl.
      </v-card-text>
      <v-card-title class="primary--text"> Product parts </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        An extension of the license can be implemented immediately. A
        termination of the product part can be effected after a minimum of one
        year of Invoicing of the license can be done after a minimum of one
        year. Numbers An increase of numbers (employees, environments, etc.) can
        be implemented immediately. implemented. A decrease in numbers can only
        be done after one year of A decrease in numbers is only possible after
        one year of full billing of the license. An increase and a decrease
        decrease within the same month is not possible.
      </v-card-text>
      <v-card-title class="primary--text">
        Termination of the Agreement
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Termination of the agreement can be sent by e-mail to
        administratie@trustedaccountant.nl. Termination by customer For the
        termination of the license a period of notice of 2 months is applicable
        before the start of a new contract year. The access to the software and
        associated data remains possible until the end of the current contract
        year. contract year. After the termination of the license, access to the
        software and associated data is no longer possible.
      </v-card-text>
      <v-card-title class="primary--text">
        Termination by Trustedaccountant.nl
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The notice period for Trustedaccountant.nl is 12 months.
        Trustedaccountant.nl has the right to terminate the contract immediately
        if the If the client does not comply with his obligations (after being
        (after having received a notice of default), has applied for a
        moratorium or has filed for bankruptcy. bankruptcy has been filed.
        Procedure customer data after termination After the end date of the
        license, all data will be deleted by Trustedaccountant.nl.
        Trustedaccountant.nl will not participate in any data migration, except
        in the following cases data migration, except in the case of importing a
        copy into another Trustedaccountant.nl license. Trustedaccountant.nl. If
        the data is to be imported into a new If the data must be read into a
        new subscription, the new subscription also has the monthly right of use
        If data is to be read into a new subscription, the monthly usage fee and
        notice period of a new subscription will also apply to the new
        subscription. The amount of The amount of the new right of use cannot be
        less than the original subscription. subscription.
      </v-card-text>
      <v-card-title class="primary--text"> Aansprakelijkheid </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.co.uk guarantees that "the Back Office" complies with
        all the specifications it provides. In case of errors
        Trustedaccountant.nl will always correct any errors as quickly as
        possible. Trustedaccountant.nl takes great care to ensure the proper
        operation of "the Backoffice" and the correct implementation of its
        services. Despite Despite these efforts, things can go wrong and cause
        damage to the client. damage to the client. Trustedaccountant.nl
        strives, in consultation with the client, to find an appropriate client
        to find an appropriate solution. The liability of Laapheer B.V. is
        limited to € 250.000,- per event or series of events with a common cause
        and will be events with a common cause and shall never exceed never
        exceed € 250.000,- per calendar year. Trustedaccountant.nl can not
        appeal to the limitations of liability in case of intent or conscious
        recklessness. Trustedaccountant.nl cannot appeal to the limitations of
        liability if there is intent or willful recklessness in the actions of
        itself, its employees or third parties it has hired.
        Trustedaccountant.nl excludes its liability for any form of
        consequential damages, such as lost sales, lost profit and missed
        opportunities. The liability of Trustedaccountant.nl is also excluded if
        the client or third parties engaged by the client make changes to
        products of Trustedaccountant.nl, which is not permitted. permitted.
        Trustedaccountant.nl and the client are not liable to each other if
        there is a Trustedaccountant.nl and the client are not liable to each
        other in the event of force majeure.
      </v-card-text>
      <v-card-title class="primary--text"> Melden </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl requires that the client reports a complaint or
        claim to Trustedaccountant.nl as soon as possible. report a complaint or
        claim to Trustedaccountant.nl as soon as possible. Not only can
        Trustedaccountant.nl Trustedaccountant.nl can then work directly with
        the client to find a solution, Trustedaccountant.nl must also report a
        claim to its insurer. insurer. However, Trustedaccountant.nl, regardless
        of the complaint and/or claim, strives to be at the top of its game in
        all aspects of its business. Trustedaccountant.nl strives, in
        consultation with the client, to find an appropriate appropriate
        solution.
      </v-card-text>
      <v-card-title class="primary--text"> Delivery terms </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        If Trustedaccountant.nl fails to meet a delivery time, the client shall
        first Trustedaccountant.nl and give Trustedaccountant.nl a reasonable
        period of time to do so. Trustedaccountant.nl a reasonable period to
        fulfill its obligations. comply with its obligations.
      </v-card-text>
      <v-card-title class="primary--text"> Processor Agreement </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl processes, among other things, personal data for
        and on behalf of order of the client because the client has a software
        user agreement with Trustedaccountant.nl. Trustedaccountant.nl and the
        client are therefore obliged under the General Data Protection
        Regulation (AVG) to enter into a Processor Agreement. Because
        Trustedaccountant.nl provides a standard application ("the Backoffice")
        with the associated standard services, Trustedaccountant.nl has
        associated standard services, Trustedaccountant.nl has
        Trustedaccountant.nl included the Processing Agreement in the General
        Terms and Conditions and SLA. Trustedaccountant.nl is in this the
        "processor" and the client is the "data controller".
        Trustedaccountant.nl and the undertake to comply with the General Data
        Protection Regulation (AVG). Data Protection Act (AVG). For the
        definitions of terms are defined in accordance with the AVG.
        Trustedaccountant.nl will process personal data only for and on behalf
        of the client and in order to execute the contract. Instructions for
        processing The processing consists of making the Trustedaccountant.nl
        applications with the data entered and generated by the client.
        generated data. Trustedaccountant.nl will not add, modify or remove data
        without the client's written consent, change or delete data without the
        written instruction of the Trustedaccountant.nl will not add, modify or
        remove data without a written instruction from the client. This
        instruction can be given through a request. given through a request.
        Within the applications that Trustedaccountant.nl makes available
        Trustedaccountant.nl provides, different types of personal data can be
        recorded. Trustedaccountant.nl is aware that the client can record all
        of these and any other personal data or categories that he or she may
        create. and any other personal data or categories that may be created by
        the customer Trustedaccountant.nl will then process them. Customer is
        responsible for the assessment whether the purpose and nature of the
        processing is appropriate for the service provided by
        Trustedaccountant.nl. Trustedaccountant.nl collects anonymized data
        about the use of its products and services. This data supports
        Trustedaccountant.nl to understand if, how and how often certain parts
        of the product are used. The anonymized data will only be used to
        improve products and services. improve. Trustedaccountant.nl will never
        use the collected statistics collected will never be used for commercial
        purposes or offer them to third parties.
        macos/deepLFree.translatedWithDeepL.text
      </v-card-text>
      <v-card-title class="primary--text"> Secrecy </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl is aware that the information that the customer
        shares with Trustedaccountant.nl and stored within "the Backoffice", has
        a secret and business-sensitive nature. All Trustedaccountant.nl
        employees are obliged by their employment contract required to keep any
        client data strictly confidential. Employees with access to customer
        data Only system administrators of "the Back Office" have full access to
        customer data for: -The Installing a new version; -Performing patches
        and hotfixes; -Creating a backup; -Moving data within "the Back Office".
        Consultants, Support staff and other Trustedaccountant.nl employees only
        have access to customer data if they have received customer data only if
        they have received permission to do so from the customer and for as long
        as they have the permission of the customer. Security
        Trustedaccountant.nl will permanently take appropriate technical and
        organizational measures to protect personal data of the customer against
        against loss or any form of unlawful processing. These measures are
        considered an appropriate security level in the sense of the AVG.
        meaning of the AVG. Client is entitled to request the use of these
        measures in consultation with Trustedaccountant.nl during the term of
        the agreement by an independent expert to verify compliance, For
        example, by performing an audit. Client will pay all costs related to
        this verification. Trustedaccountant.nl is liable for damages in the
        context of personal data by acts or omissions of the subprocessor
        whereby the limitation of liability from the Liability chapter applies.
        The applicable limitation of liability shall not apply if there is gross
        negligence or willful misconduct on the part of the sub-processor is the
        result of gross negligence or willful misconduct. Trustedaccountant.nl
        is also not liable in case of force majeure (as defined in the Liability
        chapter) on its own side or on the part of the subprocessor. If the
        Dutch Data Protection Authority will give the processor a binding
        instruction the customer must immediately notify Trustedaccountant.nl of
        this binding instruction. Trustedaccountant.nl will do everything that
        can be reasonably reasonably be expected of it to make compliance
        possible. make compliance possible. If Trustedaccountant.nl does not do
        what can reasonably be asked of it and which results in a penalty, or if
        the Authority Data Authority imposes a fine directly because there is
        intent or serious culpable negligence on the part of
        Trustedaccountant.nl. serious culpable negligence on the part of
        Trustedaccountant.nl, then the applicable limitation of liability as set
        out above in the mentioned above in the Liability chapter will not
        apply. Subprocessors Trustedaccountant.nl processes customer data in
        data centers of Digital Ocean and is therefore a subprocessor. The data
        centers that Trustedaccountant.nl uses are located exclusively in the
        Netherlands and are subject to Dutch laws and regulations and comply
        with the strict Dutch and European legislation regarding logical and
        physical access and physical access security and continuity. The data
        centers are ISO 27001 certified as a minimum. The (personal) data is
        processed by Trustedaccountant.nl and subprocessor exclusively within
        the European Economic Area. European Economic Area. Trustedaccountant.nl
        has imposed the same obligations on the subcontractor(s) the same
        obligations that apply to itself. apply to itself. Trustedaccountant.nl
        will not allow any new sub-processor to process data without informing
        the client in a timely manner. have data processed without informing the
        client in a timely manner. The customer can object to
        Trustedaccountant.nl against the subprocessor. If Trustedaccountant.nl
        still want to have data processed by the new sub-processor, the customer
        has the option to terminate the contract. terminate the agreement.
        Privacy rights Trustedaccountant.nl has no control over the personal
        data over the personal data that is made available by the customer.
        Without necessity, given the nature of the assignment provided by the
        customer assignment provided by the client, the explicit consent of the
        client or a legal obligation Trustedaccountant.nl will not provide the
        data to third parties or process it for any other other purposes, than
        for the agreed purposes. The Client warrants that the personal data may
        be processed on the basis Trustedaccountant.nl guarantees that the
        personal data may be processed on the basis of a basis mentioned in the
        AVG. Trustedaccountant.nl will, If a request is made by the Netherlands
        Authority for the Financial Markets Authority (Stichting Autoriteit
        Financiële Markten), the European Central Bank or De Nederlandsche Bank
        N.V. pursuant to the performance of their duties under the Wft or under
        other laws and regulations, Trustedaccountant.nl will do all that is
        necessary to obtain the information required. of other laws and
        regulations, make all possible information available to the organisation
        concerned. make all possible information available to the organisation
        concerned. Furthermore, Trustedaccountant.nl obliges
        Trustedaccountant.nl also obliges the subprocessor, as mentioned above,
        to comply with such a request from these supervisors.
      </v-card-text>
      <v-card-title class="primary--text"> Involved </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The customer is responsible for the data entered by the data subjects,
        never responding to requests from data subjects and always refer to the
        person responsible. Trustedaccountant.nl will, as much as is possible
        within the application, cooperate with the cooperate with the client in
        order to enable the client to comply with his legal obligations in the
        event that a data subject exercises her rights under pursuant to the AVG
        or other applicable regulations regarding the processing of personal
        data.
      </v-card-text>
      <v-card-title class="primary--text">
        Duty to report data breaches
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The AVG requires that any data breaches are reported to the Authority
        Personal Data by the data controller. Trustedaccountant.nl will
        therefore not itself make any reports to the Authority for the
        Protection of Personal Data. Naturally, Trustedaccountant.nl will inform
        the client correctly, timely and fully informs the client about relevant
        incidents, so that the client as client, as the person responsible for
        processing the data, to comply with his legal obligations. The Policy
        Rules on notifying data breaches of the Authority for the Protection of
        Personal Data provide more information in this regard. If the If the
        client makes a (preliminary) notification to the Authority
        Persoonsgegevens and/or the data subject(s) about a data breach at
        Trustedaccountant.nl, without the client having informed
        Trustedaccountant.nl, the client will be liable for the data breach.
        Trustedaccountant.nl, then the client will be liable for any damage
        suffered by Trustedaccountant.nl for the damages and costs of this
        report. If it appears that there is no data breach at
        Trustedaccountant.nl, the client is obliged to immediately withdraw the
        report.
      </v-card-text>
      <v-card-title class="primary--text">
        Determination of data breach
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Voor determining a personal data breach, Trustedaccountant.nl uses the
        AVG and the Policy Rules on Disclosure of Data Data Breach Policy as a
        guideline.
      </v-card-text>
      <v-card-title class="primary--text"> Melding aan de klant </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        If it turns out that Trustedaccountant.nl is the victim of a security
        incident or data breach, the If it appears that Trustedaccountant.nl is
        dealing with a security incident or data breach, Trustedaccountant.nl
        will inform the client as soon as possible after Trustedaccountant.nl
        will inform the client as soon as possible after Trustedaccountant.nl
        has become aware of the data breach. Trustedaccountant.nl has become
        aware of the data breach. In order to achieve this Trustedaccountant.nl
        ensures that all of its employees are and remain capable of In order to
        achieve this, Trustedaccountant.nl ensures that all of its employees are
        and remain capable of detecting a data breach and Trustedaccountant.nl
        expects its Trustedaccountant.nl expects its contractors to enable
        Trustedaccountant.nl to be able to comply with this. For the sake of
        clarity, if there is a data breach at a subprocessor of
        Trustedaccountant.nl, then, of course, Trustedaccountant.nl will report
        it. Trustedaccountant.nl also reports it. Trustedaccountant.nl is the
        contact point for the client. The customer does not have to contact the
        sub-processors of Trustedaccountant.nl Inform the client (Set up contact
        person) In the first instance Trustedaccountant.nl will Inform the
        contact person of the subscription about a data breach. If this contact
        person is not or is no longer the correct one, then this can be changed
        If the contact person is not or no longer correct, this can be changed
        by sending an email to administratie@trustedaccountant.nl.
      </v-card-text>
      <v-card-title class="primary--text"> Provide information </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.com tries to provide the customer immediately with all
        the information that the client needs in order to make a possible
        notification to the Authority for the Protection of Personal Data and/or
        the person(s) concerned.
      </v-card-text>
      <v-card-title class="primary--text"> Deadline of informing </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The AVG states that reporting must be done "without delay. According to
        Authority for the Protection of Personal Data without undue delay and if
        possible no later than 72 hours after its discovery by the responsible
        party. If a security incident occurs, Trustedaccountant.nl will notify
        the customer as soon as possible, but no later than 24 hours after the
        discovery Trustedaccountant.nl of the incident. Client will have to make
        the evaluate whether the security incident is covered by the term "data
        breach" and whether there is a data leak and whether a report to the
        Authority for the Protection of Personal Data is required. will have to
        be made. The customer has 72 hours to do so, after the customer has been
        72 hours after the customer has been informed. Progress and measures
        Trustedaccountant.nl will keep the client informed about the progress
        and measures taken. and the measures that are taken.
        Trustedaccountant.nl will make Agreements about this with the primary
        contact person at the initial notification. In any case,
        Trustedaccountant.nl will keep the client informed in case of a change
        in the situation, the disclosure of further information information and
        about the measures that will be taken. Trustedaccountant.nl registers
        all security incidents and handles them according to a fixed procedure
        (workflow). The registration and The registration and handling of
        security incidents is tested with an audit as part of the framework of
        the ISO27001 certification.
      </v-card-text>
      <v-card-title class="primary--text"> Delete data </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl will, at the end of the agreement, delete all
        client data as described under "Termination of Agreement". Agreement".
        If the customer wants to have the data removed earlier In the event that
        the client would like to have the data removed sooner, a request can be
        submitted. Trustedaccountant.nl undertakes to comply with this request.
        Exceptions to this are the backups, which are not deleted as part of the
        "forget me" protocol. as part of the forget me protocol. Service
        Trustedaccountant.nl supports the client in the implementation of "the
        Backoffice" with consultancy. consultancy. By following training
        courses, the client can make optimal use of "the back office". the
        Backoffice optimally. Consultancy Trustedaccountant.nl appoints a
        project leader for the supervision of the implementation. This project
        leader Draws up a project plan together with the client, which includes
        the planning objectives, scope and responsibilities. Each project is
        monitored up to and including delivery within the proposed budget in the
        quotation. The project leader will immediately identify and coordinate
        any overrun. The customer will ensure that a project leader is appointed
        on the The client ensures that a project leader is appointed on the
        client's side to work with the Trustedaccountant.nl project leader to
        work on the success of the implementation process. Training In order to
        be able to work properly with "the back office In order to work properly
        with "the Back Office", it is necessary that users follow training
        courses. With this knowledge, the customer himself can use "the
        Backoffice" optimally. "The Backoffice" "The Backoffice" is reliable,
        secure and fast. Trustedaccountant.nl works with external parties for
        the making Trustedaccountant.nl Online available. In addition,
        Trustedaccountant.nl works Trustedaccountant.nl works with professional
        parties to ensure security. guarantee. For the necessary maintenance,
        Trustedaccountant.nl has has made clear agreements with its supplier and
        clients regarding the necessary maintenance. Optimal backup procedures
        ensure optimal continuity. We assume a ''fair use'' approach to our
        customers. Availability "The Back Office is hosted on systems of Dutch
        professional data centers. These data centers have a network network
        availability of 95%. The availability and performance of "The
        Backoffice" are continuously monitored. If there is maintenance or a
        maintenance or a failure, the customer will be informed. be informed. In
        the following situations, "The Back Office" is not be available:
        macos/deepLFree.translatedWithDeepL.text<br />
        ·Preventive maintenance;<br />
        ·Install new version "the Backoffice";<br />
        ·Remedying malfunctions of the software which falls under the
        responsibility of the customer;<br />
        ·Onder maintenance that has been short-changed with the customer;<br />
        ·Calamities resulting from natural disasters and other force majeure
        situations.<br />
      </v-card-text>
      <v-card-title class="primary--text"> Maintenance </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The customer will be informed at least 5 days in advance if "the Back
        Office" may not be available. The work will be performed between 21:00
        and 07:00 or on weekends. Incidental patches and hotfixes are carried
        out automatically and without prior notice at night. night.
      </v-card-text>
      <v-card-title class="primary--text"> Performance </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        “The "Back Office" should have a good performance, equivalent to a local
        or network equivalent to a local or network installation, but depends on
        the Internet connection and the setup of the customer's environment. To
        To improve performance, large processing tasks are placed in a In order
        to improve performance, large processing tasks are placed in a queue and
        executed in the background. At performance problems, the service desk
        can be contacted.
      </v-card-text>
      <v-card-title class="primary--text"> Back-up & restore </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        1 time per day a backup is made of the entire production environment:
        Day backups are deleted after 7 days. Weekly backups are kept for 6
        weeks Monthly backups are kept for 18 months Backup can be restored on
        request. Average duration of restoring an environment is 4 hours.
        Monthly backups are If required, a backup of the environment can be
        requested via an incident to be requested for local use or archiving.
        For these requests accountant.nl applies a fair use policy to prevent
        large data streams. to prevent large data streams. Monitoring In "the
        Back Office", systems, processes and users are monitored with the aim of
        and users are monitored with the aim of preventing or resolving
        disruptions at an early stage. to prevent or resolve failures at an
        early stage. Monitoring is aimed at the timely discovering malfunctions
        and undesirable behavior in a timely manner. There is always, also at
        night A Trustedaccountant.nl employee is always available, even at
        night, in order to immediately resolve other emergencies immediately.
        Monitoring for abuse is part of the (daily) standard monitoring
        activities. Collecting general user statistics, such as response times.
        This information is analyzed and possibly discussed for improvement with
        the customer. Collection of anonymous statistics from the customer
        environment to improve our products and services. Security and access
        "The Back Office" uses a 'defense in depth' strategy: Thus, there are
        multiple layers of security apply. Should one of the layers fail, then
        the next layer will still offer protection. This ensures the
        availability, integrity and confidentiality of the application
        guaranteed. Trustedaccountant.nl uses cryptographic measures
        (encryption) to protect the confidentiality of the information and to be
        able to establish the authenticity of users. Continuity "The Back
        Office" has disaster recovery procedures to loss of data through system
        failure, physical destruction or otherwise prevent and facilitate the
        recovery of such data. Each customer is assigned a data center. The data
        centers are equipped with redundant techniques, so that failure of a few
        servers or storage does not does not immediately lead to a calamity. If
        necessary, we will If necessary, we will move to the other data center.
      </v-card-text>
      <v-card-title class="primary--text">
        RPO/RTO in case of calamities
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        In the event of a complete data center outage, there are no computer
        resources are no longer available. This then affects roughly half of the
        customers. Additional computer capacity will then be made available in
        the other datacenter will be made available. The RPO (recovery point
        objective) is the maximum time that data can be lost in the event of a
        complete complete failure. Due to the method of backup, this is at "the
        Back Office" a maximum of 6 hours. a maximum of 6 hours. The RTO
        (recovery time objective) is the time that is needed to make the
        available backup available. The time needed for this is not known. For
        individual recoveries as for example as a result of human errors by the
        customer user, there is always a always a backup of up to 6 hours old
        present. There can be failures There may be failures with various
        causes. In the event of a malfunction, Trustedaccountant.nl will do
        everything in its power to do everything in its power to make the
        service available again to the service available again for the client.
        Continuity datacenter in case of bankruptcy Additional (contractual)
        agreements have been made with the (contractual) agreements have been
        made with the data center to help ensure that clients of of
        Trustedaccountant.nl to retain access to their data in the event of a
        Bankruptcy: The data center will not interrupt the services to
        Trustedaccountant.nl in the event of a bankruptcy of the data center
        data center, before a continuity plan is agreed upon with the trustee.
        In the event of a bankruptcy of Trustedaccountant.nl, the datacenter for
        a minimum period of 2 weeks after the date of bankruptcy the datacenter
        will not cease its services. Before services are Before services are
        discontinued, the data center will consult with the trustee for the
        preservation of services and the securing of financial obligations.
        obligations.
      </v-card-text>
      <v-card-title class="primary--text"> Fair use </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl applies a "fair use principle". This is applied to
        the use and deployment of components of "the Back Office" other than
        that for which they are intended. Trustedaccountant.nl will contact
        customer if there is a violation of the fair use principle and will and
        will consult with the client to find a solution. Only If a solution
        cannot be found in consultation with the client, then
        Trustedaccountant.nl has the right to stop using "the Backoffice".
      </v-card-text>
      <v-card-title class="primary--text"> Support </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The employees of the Trustedaccountant.nl service desk resolve problems
        and answer questions about "the back office". Via email
        administratie@trustedaccountant.nl or by phone, an incident can by
        e-mail or by telephone. Each incident is assigned a priority. General
        Every report to the Service Desk is called an `incident'. called. An
        incident can be an error, malfunction, request or user question. be. The
        customer can determine who, on behalf of their organization, are
        authorized are authorized to contact the Service Desk on behalf of their
        organization. The Support employee also checks this with each incident.
        When a user is not a contact person with the organization no support can
        be support can be provided by the Support employee. Trustedaccountant.nl
        expects the customer to maintain this themselves, because the customer
        is best able to determine who is authorized. customer is in the best
        position to determine who is authorized. Trustedaccountant.nl is open to
        feedback. The customer can express via telephone or email his opinion
        about Trustedaccountant.nl and/or send a complaint. Trustedaccountant.nl
        will respond to the complaint within 1 day. Priorities and In principle,
        incidents are handled immediately. In the majority of In most cases,
        these are questions about knowledge of the product. the product. Most of
        these questions are handled on the same day. day. If it concerns a wish
        then this is sent in, but it is not always possible to say exactly when
        the wish will will be fulfilled. <br />
        Each incident is assigned a priority. This is assigned by The Support
        Officer who is handling the incident. The guidelines we use for this
        are: -Prio5: Desire: No specific response time;<br />
        ·Prio4: Informational questions: Same day or next day (in case of of
        exceptional congestion, the customer will be notified notification);<br />
        ·Prio3: Non-production disrupting problems: Recovery time within 1
        working week;<br />
        ·Prio2: Production Disruptive Issues: Recovery time/workaround within 1
        business day, to the extent possible;<br />
        ·Prio1: Production disrupting problems First Class: Recovery
        time/workaround within 4 hours, to the extent possible.<br />
      </v-card-text>
      <v-card-title class="primary--text"> Signal </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl identifies the number of user questions and the
        contact moments with the Service Desk. The Support Staff will determine
        whether or not to the customer is contacted to determine the cause. At
        many user questions or frequent contact with the Support Center,
        Customer Customer Care will work with the customer to find a solution to
        prevent this from happening in the future.<br />
      </v-card-text>
      <v-card-title class="primary--text"> Opening hours </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The service desk can be reached by phone from Monday to Thursday from
        09:00 - 17:00 and on Friday from 09:00 - 16:00. 09:00 - 17:00 and on
        Friday from 09:00 - 16:00.
      </v-card-text>
      <v-card-title class="primary--text"> Product </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Trustedaccountant.nl provides the customer with a product that is
        continuously developed and delivered with new developed and delivered
        with new functionalities, improvements and legal adjustments. This
        product is called "the Backoffice". This section contains information
        about the development and Version Control, the system requirements and
        product support and information about the local installation of Profit.
      </v-card-text>
      <v-card-title class="primary--text"> Productbeschrijving </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        Dsservices and responsibilities Trustedaccountant.nl develops and
        supplies the software and can, in most cases, take care of the
        implementation of this software at its clients. Customers are
        responsible for responsible for the correct set-up of the application.
        Trustedaccountant.nl supports this by providing templates based on best
        practices. based on best practices. The application is hosted via
        Trustedaccountant.nl and this service is included. and this service is
        included. Property rights The intellectual The intellectual property of
        the product is and will remain vested in Trustedaccountant.nl.
        Trustedaccountant.nl. If a third party claims that the intellectual
        property right of the software lies with him, then Trustedaccountant.nl
        will indemnify the client. A condition for this is that the client
        informs Trustedaccountant.nl as soon as possible, cooperates in the
        investigation and Trustedaccountant.nl as soon as possible, cooperate in
        the investigation and leave the handling of the case
        Trustedaccountant.nl to handle the case. If the court establishes that
        the intellectual property is indeed held by a third party,
        Trustedaccountant.nl will ensure that the client can continue to use the
        software or will provide him with the necessary use the software or
        provide him with equivalent software. The ownership of the data entered
        and the data generated by the application generated by the application
        remains with the client. Trustedaccountant.nl may and will not
        appropriate any rights to the customer data. A license does not give the
        right to the right to the so-called source code. For an Escrow-Agreement
        a separate separate agreement can be made.
      </v-card-text>
      <v-card-title class="primary--text">
        Development and versioning
      </v-card-title>
      <v-card-text class="body-1 grey--text text--darken-2">
        The software is continuously developed and delivered with new
        functionalities, improvements and legal adaptations. Globally
        Trustedaccountant.nl applies the following delivery schedule: Version:
        2-4 times per year; Patch: Daily if applicable. Each Version is provided
        with Releasenotes. These describe which parts parts have been changed.
        Statutory adjustments and changes in the software are only implemented
        in the latest, most current version. version. Update new version The
        update to a new version will be carried out be carried out in phases. A
        customer is assigned to a time schedule and can choose to switch earlier
        or later. The date of of migration will be made final at least 5 days in
        advance. The total version migration time is 2-3 weeks.
        Trustedaccountant.nl is in its delivery policy dependent on legal
        changes by government and agencies. by the government and agencies.
        Usually, legal changes shortly before the effective date or even with
        retroactive effect. made definitive shortly before the effective date or
        even retroactively. Trustedaccountant.nl tries to anticipate legal
        changes in time. legal changes in the software. For updates with legal
        changes that must be installed before a certain date,
        Trustedaccountant.nl may deviate from the delivery schedule.
        Trustedaccountant.nl deviate from the delivery policy. In the migration
        from one version to the next, the data will be temporarily unavailable.
        available. The time that the data is unavailable is highly dependent on
        a number of factors, such as: type of data conversion, size of of
        database, amount of records to be modified. Experience shows that the
        maximum time is between 5 minutes and 2.5 hours. These activities are
        carried out as much as possible outside office hours (Mon-Fri: 7:00 -
        18:00) and are communicated and communicated well in advance.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "profileAlg",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeWin() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
