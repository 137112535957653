<template>
  <div class="secondary mt-5">
    <v-row class="ml-5 mt-5 mr-5">
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/vermregie/verm_1a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Net wealth</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Virtually every SME entrepreneur is narrowly asking themselves "What
            can I afford". This need for financial independence linked to the
            wishes and goals is very well suited to the accountant or tax
            specialist. We are currently developing an inventory module, which
            translates the capital in Box I, II and III to a net asset. By
            making various analyses, it becomes it becomes clear how the assets
            are structured and what the applicable return/risk ratio.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/vermregie/verm_2a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text">Board game</v-card-title>
          <v-card-text class="body-1 grey--text text--darken-2">
            Guiding the thought process about what is really important to be
            financially fulfilled is a complex process. We developed an
            interactive method that challenges entrepreneurs and and their life
            partners to further investigate and discover what their life goals
            are. We call this the Board Game, whereby it gradually becomes clear
            which goals, with which priority and horizon, the customer really
            has.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-4 d-flex justify-space-around">
        <v-card
          elevation="15"
          shaped
          class="justify-space-around mb-5"
          width="100%"
        >
          <v-img :src="require('@/assets/vermregie/verm_3a.jpg')" width="100%">
          </v-img>
          <v-card-title class="primary--text"
            >Working capital analysis</v-card-title
          >
          <v-card-text class="body-1 grey--text text--darken-2">
            We are currently developing the Cash Conversion Cycle methodology
            for accountants. Making clients aware of the number of days that the
            assets are tied up in the primary process is the first step towards
            more effective more effective working capital management and
            significant cost savings.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "vermBseg",
};
</script>

<style scoped></style>
