<template>
  <v-col class="ml-5 col-11 col-md-3 d-flex">
    <v-row no-gutters justify="center">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            elevation="15"
            shaped
            class="justify-space-around mb-5"
            width="100%"
            height="350px"
          >
            <v-card-text class="text-center mt-15">
              <v-btn elevation="15" fab dark large color="accent">
                <v-icon> mdi-family-tree </v-icon>
              </v-btn>
            </v-card-text>
            <v-card-title class="justify-center primary--text"
              >Business family advantage</v-card-title
            >

            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                opacity="1"
                color="secondary"
                class="ml-3 mr-3"
              >
                <v-card-text class="body-1 grey--text text--darken-5">
                  Your added value lies in guiding the decision-making on the
                  various issues and acting as moderator to bridge differences.
                  The other process process steps we have filled in with smart
                  technology, so that you have virtually no handling.
                </v-card-text>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "kenmB",
};
</script>

<style scoped></style>
